// formik
import { Formik } from "formik";
import * as Yup from "yup";

// components
import PasswordRequirements from "./PasswordRequirements";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import {
  StyledPasswordField,
  StyledSubmitButton,
} from "./styles/CreateAccountForm";

import { CreateAccountFormSubmitHandler } from "../types";

const passwordStrengthRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^*()\-_=+[{\]}\\;:'",<.>/? ])/;

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Required")
    .min(11, "Minimum 11 characters")
    .max(255, "Maximum 255 characters")
    .matches(passwordStrengthRegex, "Does not satisfy password requirements"),

  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

interface Props {
  onSubmit: CreateAccountFormSubmitHandler;
}

const CreateAccountForm = ({ onSubmit }: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} noValidate>
          {formik.status?.showAlert && (
            <div
              className={`alert ${
                formik.status.success ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {formik.status.message}
            </div>
          )}

          <div className="form-group">
            <label className="sr-only" htmlFor="password">
              Password
            </label>
            <StyledPasswordField
              className="form-control"
              type="password"
              placeholder="Password"
              {...formik.getFieldProps("password")}
            />
          </div>

          <div className="mb-3">
            <PasswordStrengthMeter
              password={formik.values.password}
              confirmPassword={formik.values.confirmPassword}
            />
          </div>

          <div className="form-group mb-3">
            <label className="sr-only" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <StyledPasswordField
              className="form-control"
              type="password"
              placeholder="Confirm Password"
              {...formik.getFieldProps("confirmPassword")}
            />
          </div>

          <PasswordRequirements />

          <div className="d-grid">
            <StyledSubmitButton
              className="btn btn-primary"
              type="submit"
              disabled={
                formik.isSubmitting ||
                !formik.touched.password ||
                !formik.isValid ||
                formik.status?.success
              }
            >
              Create Account
            </StyledSubmitButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreateAccountForm;
