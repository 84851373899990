// react-router
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";

// pages
import BadRoute from "./pages/BadRoute";
import CreateAccountPage from "./pages/CreateAccountPage";

// components
import Container from "./components/Container";

// styled components
import GlobalStyle from "./components/styles/GlobalStyle";

const App = () => {
  return (
    <BrowserRouter basename="/password-tool">
      <GlobalStyle />
      <Container>
        <Routes>
          <Route path="*" element={<BadRoute />} />
          <Route
            path="/create-account/:token"
            element={<CreateAccountPage />}
          />
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;
