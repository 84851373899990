// password strength library
import zxcvbn from "zxcvbn";

// styled components
import {
  PasswordBarColor,
  PasswordBarWidth,
  StyledFeedbackNote,
  StyledPasswordStrengthBar,
} from "./styles/PasswordStrengthMeter";

interface PasswordFeedback {
  result: "Weak" | "Low" | "Medium" | "High" | "Strong";
  notes: string[];
}
interface Props {
  password: string;
  confirmPassword: string;
}

const getPasswordFeedback = (
  password: string,
  confirmPassword: string,
  score: number
): PasswordFeedback => {
  const feedback: PasswordFeedback = {
    result: "Weak",
    notes: [],
  };

  if (password) {
    switch (score) {
      case 1:
        feedback.result = "Low";
        break;
      case 2:
        feedback.result = "Medium";
        break;
      case 3:
        feedback.result = "High";
        break;
      case 4:
        feedback.result = "Strong";
        break;
    }

    if (password.length < 11) {
      feedback.notes.push("Password is too short");
    }

    if (!/[A-Z]/.test(password)) {
      feedback.notes.push("Password must contain an uppercase letter");
    }

    if (!/[a-z]/.test(password)) {
      feedback.notes.push("Password must contain a lowercase letter");
    }

    if (!/[0-9]/.test(password)) {
      feedback.notes.push("Password must contain a number");
    }

    if (!/[~!@#$%^*()\-_=+[\]{}\\;:'",.<>/?]/.test(password)) {
      feedback.notes.push("Password must contain a special character");
    }

    if (confirmPassword && password !== confirmPassword) {
      feedback.notes.push("Entered passwords do not match");
    }
  }

  return feedback;
};

const PasswordStrengthMeter = ({ password, confirmPassword }: Props) => {
  const strength = zxcvbn(password);
  const feedback = getPasswordFeedback(
    password,
    confirmPassword,
    strength.score
  );

  let width: PasswordBarWidth = 1;
  let color: PasswordBarColor = "red";

  switch (strength.score) {
    case 1:
      width = 25;
      break;

    case 2:
      color = "yellow";
      width = 50;
      break;

    case 3:
      color = "blue";
      width = 75;
      break;

    case 4:
      color = "green";
      width = 100;
      break;
  }

  return (
    <>
      <div className="progress">
        <StyledPasswordStrengthBar
          width={width}
          color={color}
          className={`progress-bar`}
          role="progressbar"
          aria-valuenow={strength.score}
          aria-valuemin={0}
          aria-valuemax={4}
        ></StyledPasswordStrengthBar>
      </div>
      <div>
        <p className="mb-0">{feedback.result}</p>
        {feedback.notes && (
          <ul>
            {feedback.notes.map((note, index) => (
              <li key={index}>
                <StyledFeedbackNote className="feedback-note">
                  {note}
                </StyledFeedbackNote>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
