import styled from "styled-components";

export const StyledPasswordField = styled.input`
  background-color: #f7f9fc;
  border: none;
  border-bottom: 1px solid #def7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: #505e6c;
  text-indent: 8px;
  height: 42px;

  &:focus {
    color: #505e6c;
    background-color: #f7f9fc;
    border-bottom-color: #def7f1;
    outline: none;
    box-shadow: none;
  }
`;

export const StyledSubmitButton = styled.button`
  background-color: #bc4132;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
  padding: 11px;
  text-shadow: none;

  &:hover {
    background-color: #4ac5b5;
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    background-color: #4ac5b5;
    transform: translateY(1px);

    &:focus {
      box-shadow: none;
    }
  }

  &:disabled {
    background-color: #bc4132;
  }
`;
