import styled from "styled-components";

export const StyledContainer = styled.main`
  width: 90%;
  max-width: 480px;
  padding: 40px;
  margin: 0 auto;
  color: #505e6c;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
`;
