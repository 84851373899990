import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import CreateAccountForm from "../components/CreateAccountForm";
import ErrorBanner from "../components/ErrorBanner";
import Logo from "../components/Logo";

import {
  CreateAccountFormSubmitHandler,
  NewAccountResponseSuccess,
  ValidCloud,
} from "../types";

const CreateAccountPage = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [cloud, setCloud] = useState<ValidCloud>("internal");
  const [username, setUsername] = useState("");

  const url = `${process.env.REACT_APP_BACKEND_URL}/account`;

  // form handler
  const onCreateAccount: CreateAccountFormSubmitHandler = async (
    values,
    actions
  ) => {
    const status = {
      success: false,
      showAlert: false,
      message: "",
    };

    try {
      if (!token) return;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          token,
          password: values.password,
          confirm_password: values.confirmPassword,
        }),
      });
      const json = await response.json();

      status.showAlert = true;

      if (response.ok) {
        const data = json as NewAccountResponseSuccess;

        status.success = true;
        status.message = data.detail;

        actions.resetForm();
        actions.setStatus(status);
      } else {
        if (response.status === 403) {
          status.message = `Received 403 Foribben from upstream API`;
          actions.setStatus(status);
        } else {
          status.message = "Unknown API response";
          actions.setStatus(status);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        status.message = error.message;
        actions.setStatus(status);
      }
    }
  };

  // load data from token on page load
  useEffect(() => {
    (async () => {
      if (!token) return;

      try {
        const response = await fetch(
          `${url}?${new URLSearchParams({ token })}`
        );
        const data = await response.json();

        if (response.ok) {
          setCloud(data.cloud);
          setUsername(data.username);
          setIsValidToken(true);
        }
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    })();
  }, [token, url]);

  if (isLoading) {
    return <ErrorBanner cloud={cloud} message="Loading..." />;
  }

  if (!isValidToken) {
    return <ErrorBanner cloud={cloud} message="Invalid token!" />;
  }

  return (
    <>
      <Logo cloud={cloud} />

      <div>
        <p>
          Create a password for your new <strong>{username}</strong> account.
        </p>
      </div>

      <CreateAccountForm onSubmit={onCreateAccount} />
    </>
  );
};

export default CreateAccountPage;
