import styled from "styled-components";

export const StyledLink = styled.a`
  color: #495057;
  display: block;
  font-size: 12px;
  opacity: 0.9;

  &:hover {
    color: #0056b3;
  }
`;
