import styled from "styled-components";

export type PasswordBarColor = "red" | "yellow" | "blue" | "green";
export type PasswordBarWidth = 1 | 25 | 50 | 75 | 100;

export interface StyledPasswordStrengthBarProps {
  color: PasswordBarColor;
  width: PasswordBarWidth;
}

export const StyledFeedbackNote = styled.span`
  color: var(--bs-red);
`;

export const StyledPasswordStrengthBar = styled.div`
  width: ${({ width }: StyledPasswordStrengthBarProps) => width}%;
  background-color: var(
    --bs-${({ color }: StyledPasswordStrengthBarProps) => color}
  );
`;
