import { useState } from "react";
import { StyledLink } from "./styles/PasswordRequirements";

const PasswordRequirements = () => {
  const [hidden, setHidden] = useState(true);

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  return (
    <div className="mb-4 pt-2">
      <div className="card-header">
        <StyledLink href="#requirements" onClick={toggleHidden}>
          Password Requirements &#x2c5;
        </StyledLink>
      </div>

      <div className={hidden ? "d-none" : ""}>
        <div className="card card-body">
          <ul>
            <li>At least 11 characters</li>
            <li>At least 1 letter</li>
            <li>At least 1 number</li>
            <li>
              At least 1 punctuation mark ~!@#$%^*()-_=+[]{"{}"}
              \:;"'&lt;&gt;,./?
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PasswordRequirements;
