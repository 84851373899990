// components
import Logo from "./Logo";

// types
import { ValidCloud } from "../types";

interface Props {
  cloud: ValidCloud;
  message: string;
}

const ErrorBanner = ({ cloud, message }: Props) => {
  return (
    <>
      <Logo cloud={cloud} />
      <h2>{message}</h2>
    </>
  );
};

export default ErrorBanner;
