// images
import logo_areon from "../images/logos/areon.svg";
import logo_cen from "../images/logos/cen.svg";
import logo_i2 from "../images/logos/i2.svg";
import logo_internal from "../images/logos/internal.svg";
import logo_onenet from "../images/logos/onenet.svg";
import logo_oshean from "../images/logos/oshean.svg";

// styled components
import { StyledLogo } from "./styles/Logo";

interface Props {
  cloud: "areon" | "cen" | "i2" | "internal" | "onenet" | "oshean";
}

const Logo = ({ cloud }: Props) => {
  const logoMap = {
    areon: logo_areon,
    cen: logo_cen,
    i2: logo_i2,
    internal: logo_internal,
    onenet: logo_onenet,
    oshean: logo_oshean,
  };

  return (
    <StyledLogo>
      <img
        className="img-fluid img-responsive"
        src={logoMap[cloud]}
        alt="Logo"
      />
    </StyledLogo>
  );
};

export default Logo;
